
import EuLogoColor from '../../assets/images/eulogocolor.png';
import EuLogoWhite from '../../assets/images/eulogowhite.png';

import {Row, Col} from 'reactstrap';

export function EuLogo() {
    return <div>
        <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
                <img className="img-fluid mt-4 mb-3 d-block mx-auto" style={{ width: '70%' }} src={EuLogoColor} alt="" />
            </Col>
        </Row>
        <p className="text-center">Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or the European Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.</p>
    </div>;
}

export function EuLogoMenu() {
    return <div>
        <img className="img-fluid d-block mx-auto" style={{ width: '60%' }} src={EuLogoWhite} alt="" />
    </div>;
}