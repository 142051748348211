import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from "reactstrap";

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                    <footer className="footer">
                        <Container fluid>
                            <Row>
                                <Col sm="12">
                                    <FormattedMessage id="COPYRIGHT" values={{ year: new Date().getFullYear() }} />
                                </Col>
                            </Row>
                            <Container>
                                <p className="text-center text-dark pb-0" style={{ fontSize: '.75em' }}>Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or the European Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.</p>
                            </Container>
                        </Container>

                    </footer>
            </React.Fragment>
        );
    }
}

export default Footer;